import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SoftList from '../views/SoftList.vue'
import SoftForm from '../views/SoftForm.vue'
import Menu from '../views/Menu.vue'

Vue.use(VueRouter)

const routes = [
  // トップページ
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // ソフト一覧ページ
  {
    path: '/list',
    name: 'list',
    component: SoftList,
    props: true
  },
  // ソフトコレクション情報のフォーム
  {
    path: '/form',
    name: 'form',
    component: SoftForm
  },
  // メニューページ
  {
    path: '/menu',
    name: 'menu',
    component: Menu
  },
  // その他のアクセスはトップページへ
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    // [戻る]ボタンでの移動は以前の位置を保持
    if (savedPosition) {
      return savedPosition;
    } else {
      // ハッシュ（#～）がある場合は指定の要素位置へ
      if (to.hash) {
        return { selector: to.hash};
      // それ以外は先頭位置に移動
      } else {
        return { x: 0, y: 0};
      }
    }
  }
})

export default router
