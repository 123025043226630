<template>
  <div class="modal" :class="{'isOpen': isModalActive}">
    <div class="modalWrapper" @click="onClick"></div>
    <div class="modalContent">
      <p>タイトル頭文字</p>
      <ul class="initialList">
        <li v-for="i in this.initialList" :key="i" @click="initialClick(i)">{{i}}</li>
      </ul>
      <span class="clear" @click="clearClick">クリア</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { UPDATE_INITIAL } from '../store/mutation-types';
export default {
  data() {
    return {
      initialList: [
        "あ","い","う","え","お",
        "か","き","く","け","こ",
        "さ","し","す","せ","そ",
        "た","ち","つ","て","と",
        "な","に","ぬ","ね","の",
        "は","ひ","ふ","へ","ほ",
        "ま","み","む","め","も",
        "や","ゆ","よ",
        "ら","り","る","れ","ろ",
        "わ"
      ]
    }
  },
  props: {
    isModalActive: Boolean
  },
  methods: {
    // store情報のアクションを同名のメソッドに紐づけ
    ...mapActions([UPDATE_INITIAL]),
    // モーダル画面のクリックイベント
    onClick() {
      this.$emit('closeModalWindow'); //モーダル画面を閉じる
    },
    // タイトルイニシャル（五十音）情報を親ページのイベントを発生させセット
    initialClick(initial) {
      // 選択したイニシャル情報をステートに保存
      this[UPDATE_INITIAL](initial);
      //this.$emit("selectInitial", initial);
      this.$emit('closeModalWindow'); //モーダル画面を閉じる
    },
    // [クリア]ボタンクリックイベント
    clearClick() {
      // イニシャル情報をクリア
      this[UPDATE_INITIAL]('');
      //this.$emit("selectInitial", initial);
      this.$emit('closeModalWindow'); //モーダル画面を閉じる
    }
  }
}
</script>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}
.isOpen {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.modalWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.modalContent {
  position :absolute;
  top: 54px;
  left: 10px;
  width: calc(100% - 20px);
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  opacity: 1;
}
.modalContent p {
  text-align: center;
  font-weight: 700;
}
.modalContent .clear {
  display: block;
  width: 60px;
  padding: 5px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
.initialList {
  display: flex;
  flex-flow: row wrap;
}
.initialList li {
  width: 19%;
  margin: 0 1% 10px 0;
  font-size: 1.8rem;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
</style>