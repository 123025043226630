import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// $httpプロパティを追加
Vue.prototype.$http = (url) => fetch(url)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//serviceWorkerの登録
if ("serviceWorker" in navigator) {
  /*
  navigator.serviceWorker.register("/sw.js")
    .catch(() => {});
  */
  navigator.serviceWorker
    .register("/sw.js")
    .then(registation => {
      registation.onupdatefound = function() {
        console.log('sw updated');
        registation.update();
      }
    })
    .catch((err) => {
      console.log('sw Update failed' + err);
    });
}