export const GAME_CONSOLES = [
  {ini: "fc", name: "ファミリーコンピュータ"}, //[0]
  {ini: "fds", name: "FCディスクシステム"}, //[1]
  {ini: "gb", name: "ゲームボーイ"}, //[2]
  {ini: "sfc", name: "スーパーファミコン"}, //[3]
  {ini: "pce", name: "PCエンジン"}, //[4]
  {ini: "md", name: "メガドライブ"}, //[5]
  {ini: "ng", name: "ネオジオ"}, //[6]
  {ini: "gg", name: "ゲームギア"}, //[7]
  {ini: "gba", name: "ゲームボーイアドバンス"}, //[8]
  {ini: "ws", name: "ワンダースワン"}, //[9]
  {ini: "ngp", name: "ネオジオポケット"}, //[10]
];