import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { UPDATE_SOFT, UPDATE_CURRENT, UPDATE_COLLECTION, UPDATE_DISPTARGET, UPDATE_KEYWORD, UPDATE_INITIAL, UPDATE_GAMECONSOLE, UPDATE_ALL_COLLECTION, UPDATE_SCROLLY, UPDATE_SHOW_GROUPNO } from './mutation-types';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    // ゲーム機種No
    gameConsole: null,
    // ソフト情報リスト
    softs: [],
    // 現在編集／選択中のソフト情報
    current: null,
    // 現在選択中の表示指定ボタン情報
    dispTarget: null,
    // タイトル検索キーワード
    keyword: "",
    // タイトル頭文字（五十音）
    initial: "",
    // スクロールY位置   
    scrollY: null,
    // 表示するリストのグループNo   
    showGroupNo: null,
    // コレクション情報リスト配列（2次元）※ゲーム機の配列数を最大12に固定
    collections: [
      [],[],[],[],[],[],[],[],[],[],[],[]
    ]
  },
  getters: {
    //現在選択中のゲーム機種情報
    gameConsole(state) {
      return state.gameConsole;
    },
    // 登録済みのソフト数
    softsCount(state) {
      return state.softs.length;
    },
    // すべてのソフト情報リスト
    allSofts(state) {
      return state.softs;
    },
    // 現在編集中のコレクション情報
    current(state) {
      return state.current;
    },
    // 現在選択中の表示指定ボタン情報
    dispTarget(state) {
      return state.dispTarget;
    },
    // タイトル検索キーワード
    keyword(state) {
      return state.keyword;
    },
    // タイトル頭文字（五十音）
    initial(state) {
      return state.initial;
    },
    // 現在のスクロール位置(Y)
    scrollY(state) {
      return state.scrollY;
    },
    // 現在の表示するリストのグループNo
    showGroupNo(state) {
      return state.showGroupNo;
    },
    // すべてのコレクション情報リスト
    allCollections(state) {
      return state.collections;
    },
    // 指定されたidのコレクション情報
    getCollectionById(state) {
      // 引数idをキーに配列collectionsを検索
      return (id) => {
        // 既存のゲーム機情報の配列がある場合
        if (state.gameConsole < state.collections.length) {
          // ソフトIDに該当するコレクション情報をかえす
          return state.collections[state.gameConsole].find(collection => collection.id == id);
        } else {
          return;
        }
      }
    }
  },
  mutations: {
    // 選択中のゲーム機種情報を更新
    [UPDATE_GAMECONSOLE](state, payload) {
      state.gameConsole = payload;
    },
    // ソフト情報リストを更新（引数payloadは全ソフト情報）
    [UPDATE_SOFT](state, payload) {
      //console.log(payload);
      this.state.softs = []; //配列をクリア
      Object.assign(state.softs, payload);
    },
    // 編集中のコレクション（current）を更新
    [UPDATE_CURRENT](state, payload) {
      state.current = payload;
    },
    // 編集中の表示指定ボタン（dispTarger）を更新
    [UPDATE_DISPTARGET](state, payload) {
      state.dispTarget = payload;
    },
    // タイトル検索キーワード(keyword)を更新
    [UPDATE_KEYWORD](state, payload) {
      state.keyword = payload;
    },
    // タイトル頭文字（五十音）を更新
    [UPDATE_INITIAL](state, payload) {
      state.initial = payload;
    },
    // 現在のスクロール情報を更新
    [UPDATE_SCROLLY](state, payload) {
      state.scrollY = payload;
    },
    // 現在の表示するリストのグループNo情報を更新
    [UPDATE_SHOW_GROUPNO](state, payload) {
      state.showGroupNo = payload;
    },
    // コレクション情報リストを更新（引数payloadは更新されたソフト情報）
    [UPDATE_COLLECTION](state, payload) {
      // id値（payload.id）で既存のコレクション情報を検索
      let collection = this.getters.getCollectionById(payload.id);
      if (collection) {
        // 既存のコレクション情報がある場合は、更新情報（payload）で上書き
        Object.assign(collection, payload);
      } else {
        // コレクション配列（2次元）に選択中のゲーム機の配列がある場合
        if (state.gameConsole < state.collections.length) {
          // コレクション情報の配列に新規追加
          state.collections[state.gameConsole].push(payload);
        // 新規に追加したゲーム機の場合
        } else {
          let newGameCollections = [];
          // 新規のゲーム機のコレクション配列へ追加
          newGameCollections.push(payload);
          // 既存のコレクション配列（2次元）へ新しいゲーム機のコレクション配列を追加
          state.collections.push(newGameCollections);
        }
      }
    },
    // 全てのゲーム機のコレクション情報リストを更新（引数payloadは更新されたコレクション情報）
    [UPDATE_ALL_COLLECTION](state, payload) {
      // コレクション情報リスト配列（2次元）へセット
      state.collections = payload;
    }
  },
  // ミューテーションに対応する同名のアクション
  actions: {
    [UPDATE_GAMECONSOLE]({commit}, payload) {
      commit(UPDATE_GAMECONSOLE, payload);
    },
    [UPDATE_SOFT]({commit}, payload) {
      commit(UPDATE_SOFT, payload);
    },
    [UPDATE_CURRENT]({commit}, payload) {
      commit(UPDATE_CURRENT, payload);
    },
    [UPDATE_DISPTARGET]({commit}, payload) {
      commit(UPDATE_DISPTARGET, payload);
    },
    [UPDATE_KEYWORD]({commit}, payload) {
      commit(UPDATE_KEYWORD, payload);
    },
    [UPDATE_INITIAL]({commit}, payload) {
      commit(UPDATE_INITIAL, payload);
    },
    [UPDATE_SCROLLY]({commit}, payload) {
      commit(UPDATE_SCROLLY, payload);
    },
    [UPDATE_SHOW_GROUPNO]({commit}, payload) {
      commit(UPDATE_SHOW_GROUPNO, payload);
    },
    [UPDATE_COLLECTION]({commit}, payload) {
      commit(UPDATE_COLLECTION, payload);
    },
    [UPDATE_ALL_COLLECTION]({commit}, payload) {
      commit(UPDATE_ALL_COLLECTION, payload);
    }
  },
  // ストレージ保存のためのプラグインを有効化
  plugins: [createPersistedState({
    key: 'pico-colle',
    strage: localStorage
  })]
})
