<template>
<div class="kiyakuContent">
    <section>
    <h2>1．基本条件</h2>
    <p>この利用規約（以下、当規約）は<span class="fontBold">PICOMINI</span>の各サービス（以下、当サイト）のテキスト、画像、写真、動画、その他のコンテンツ（以下、当サイトのコンテンツ）を利用する場合に適用されるものです。ユーザーが当サイトへアクセスし利用するためには、本規約に同意し遵守することが条件となり、アカウント情報および当サイトで情報を登録した場合には、当規約に拘束されることに同意したこととなります。</p>
    <p>ユーザーは当サイトを利用したことより引き起こされる結果の全てについて責任を負います。また、ユーザーが当サイトへ送信または登録する情報（パスワード等の個人情報は除く）の一部は、他の利用者やウェブサイトを通じて閲覧可能になります。当サイトは、ユーザーに事前に通知することなく内容を変更することがあり、その旨をユーザーに事前に通知できない場合もあります。さらに、当サイトは独自の判断により、サービスの利用制限や一時停止または中止する権利を有します。</p>
    </section>
    <section>
    <h2>2．利用の制限</h2>
    <p>以下に該当する場合のご利用はお断りします。<br />
    違反した場合は警告または通告なしにアカウント情報およびその他の登録情報を削除し、悪質な場合は警察に通報いたします。</p>
    <ul class="listMark">
    <li>当サイトの運営を妨げる恐れのあるもの</li>
    <li>当サイトと関連のない目的で使用するもの</li>
    <li>他人の著作権またはその他の権利を侵害または侵害する恐れのあるもの</li>
    <li>当サイトの利用者や第三者を誹謗中傷したり、公序良俗に反するもの</li>
    <li>マルチ商法、マルチまがい、悪徳商法、霊感商法、ねずみ講</li>
    <li>出会い系、アダルトコンテンツ等、性的表現が含まれるもの</li>
    <li>詐欺行為または虚偽の情報を含むもの</li>
    <li>その他、当サイトが不適当と判断した場合</li>
    </ul>
    </section>
    <section>
    <h2>3．免責事項について</h2>
    <dl>
    <dt>個人情報の掲載</dt>
    <dd><p>当サイトに登録されたアカウント情報や各サービスで登録された情報について、当サイトは責任を負いません。必要以上の個人情報や重要な情報は記載しないようにしてください。また、それを記載したことにより発生したいかなる損害についても当サイトは一切責任を負いません。
    </p>
    </dd>
    <dt>著作権</dt>
    <dd><p>当サイトの著作権は、<span class="fontBold">PICOMINI</span>に帰属します。（ただし、一部のテキストや画像など当サイト以外の著作物を引用した情報についてはその著作権者に帰属します。）当サイトのコンテンツの情報は、私的使用目的の複製、引用など著作権法上認められた範囲をのぞき、無断で転載、複製、改編することを禁じます。ユーザーが登録したテキストや画像、写真、動画等についてはユーザー自身に帰属し、当サイトを運営する目的で<span class="fontBold">PICOMINI</span>が使用及び改変することを許諾します。</p></dd>
    <dt>当サイトの情報、配布物、リンク先の情報等</dt>
    <dd>
    <p>当サイト上に掲載されている情報やコンテンツについては慎重に作成、管理しておりますが、その完全性を保証するものではありません。サービスの運営上、内容の変更が必要な場合は、ご利用者に事前にお知らせをすることなく変更やサービスの停止を行うことがあります。ページ内容の更新やサービスの停止、フリーの配布物、天災・災害および通信回線やサーバーなどシステムの故障等で生じたいかなる損害についても当サイトは責任を負いません。また、当サイトのリンク先に記載されている情報について、その正確性を保証することはできません。記載されている情報等によって発生したいかなる損害についても、当サイトは一切責任を負いません。</p></dd>
    </dl>
    </section>
    <section>
    <h2>4．当サイトへのリンクについて</h2>
    <p>当サイトへのリンクは営利、非営利を問わず原則自由とし、事前のご連絡は不要です。<br>ただし、以下に該当するウェブサイトやアプリ等からのリンクはお断りします。</p>
    <ul class="listMark">
    <li>当サイトまたは当サイトに登録されたユーザーの情報を誹謗・中傷するサイト</li>
    <li>違法、または違法である可能性が高い情報を提供するサイト</li>
    <li>猥褻、あるいは公序良俗に反する可能性のある情報を提供するサイト</li>
    <li>フレーム分割や埋め込み等により、当サイトと第三者のサイトが混在し、当サイトであることが不明確となるサイト</li>
    <li>その他、当サイトが不適当と判断したサイト</li>
    </ul>
    <p>当サイトへのリンクによって発生したトラブルや損害等について当サイトは一切責任を負いません。</p>
    </section>
    <section>
    <h2>5．利用規約の改訂について</h2>
    <p>当規約は事前の通知なしに変更する場合があります。</p>
    </section>
    <div class="postUpdate">
    <span>最終更新日: <time datetime="2024-10-03">2024-10-03</time></span>
    </div><!-- /.postUpdate -->
</div>
</template>

<script>
export default {
  name: 'Kiyaku'
}
</script>

<style>
.kiyakuContent {
  height: 200px;
  padding: 5px;
  border: 1px solid #ddd;
  overflow: scroll;
  background-color: #fff;
}
.kiyakuContent section h2 {
  font-size: 1.4rem;
}
.kiyakuContent p,
.kiyakuContent ul,
.kiyakuContent dl {
  font-size: 1.3rem;
}
</style>