<template>
  <div class="softForm" :class="this.gameConsoles[gameConsole]['ini']">
    <!-- ローディングウィンドウのコンポーネント -->
    <MyLoading :isLoadingActive="this.isLoading"></MyLoading>
    <header>
      <div class="header-inner">
        <!-- [<]戻るボタン -->
        <!--<router-link :to="{name: 'list', params: {outerSlide: 'slideL'}}">-->
          <span class="btnBack" @click="onBtnBack"></span>
        <!--</router-link>-->
        <h1>{{this.gameConsoles[gameConsole]["name"]}}</h1>
      </div>
    </header>
    <transition name="slideR" appear>
    <div class="container">
      <form>
        <!-- 選択中のソフト情報を表示 -->
        <div class="softInfo">
          <div class="softDetail">
            <!--<img :src="soft.image">-->
            <h2 :class="this.gameConsoles[gameConsole]['ini'] +'_soft'+ soft.icNo">{{soft.name}}</h2>
            <dl>
              <!--<dt>No. </dt><dd v-if="index">{{index}}</dd>-->
              <!--<dt>ID：</dt><dd>{{soft.id}}</dd>-->
              <!--<dt>INI：</dt><dd>{{soft.initial}}</dd>-->
              <div class="col2">
                <dt>発売日</dt><dd>{{soft.releaseDate}}</dd>
              </div>
              <div class="col2">
                <dt>定価</dt>
                <dd v-if="soft.price > 0">{{Number(soft.price).toLocaleString()}}円</dd>
                <dd v-else>？円</dd>
              </div>
              <div>
                <dt>メーカー</dt><dd>{{soft.maker}}</dd>
              </div>
              <div>
                <dt>ジャンル</dt><dd>{{soft.genre}}</dd>
              </div>
            </dl>
          </div>
        </div>
        <!-- コレクション情報の入力フォーム -->
        <div class="collectionForm">
          <!-- ソフト所持数&ブックマーク -->
          <div class="collectionNumMark">
            <!-- 所持数 -->
            <div class="softNum">
              <label for="ibNum">所持数</label>
              <span class="btnNum btnMinus" @click="setNum(false)">-</span>
              <span class="btnNum dispNum">{{form.num}}</span>
              <input type="text" id="ibNum" v-model="form.num" minlength="0" maxlength="2" disabled>
              <span class="btnNum btnPlus" @click="setNum(true)">+</span>
            </div>
            <!-- ブックマーク -->
            <div class="bookmark">
              <label for="cbBookmark" :class="{'active': form.bookmark}"></label>
              <input type="checkbox" id="cbBookmark" v-model="form.bookmark">
            </div>
          </div>
          <!-- コレクション1 -->
          <div class="collection" :class="{'show': form.num >= 1}">
            <label class="lbCollection">ソフト1</label>
            <div class="collectionItem">
              <div class="rbGroup">
                <label for="rbRankA1" class="lbRank" :class="{'active': form.rank1 === 'A'}">A</label>
                <input type="radio" id="rbRankA1" value="A" v-model="form.rank1">
                <label for="rbRankB1" class="lbRank" :class="{'active': form.rank1 === 'B'}">B</label>
                <input type="radio" id="rbRankB1" value="B" v-model="form.rank1">
                <label for="rbRankC1" class="lbRank" :class="{'active': form.rank1 === 'C'}">C</label>
                <input type="radio" id="rbRankC1" value="C" v-model="form.rank1">
              </div>
              <div class="cbGroup">
                <label for="cbBox1" class="lbBox" :class="{'active': form.box1}"></label>
                <input type="checkbox" id="cbBox1" v-model="form.box1">
                <label for="cbManual1" class="lbManual" :class="{'active': form.manual1}"></label>
                <input type="checkbox" id="cbManual1" v-model="form.manual1">
              </div>
            </div>
          </div><!--/.collection-->
          <!-- コレクション2 -->
          <div class="collection" :class="{'show': form.num >= 2}">
            <label class="lbCollection">ソフト2</label>
            <div class="collectionItem">
              <div class="rbGroup">
                <label for="rbRankA2" class="lbRank" :class="{'active': form.rank2 === 'A'}">A</label>
                <input type="radio" id="rbRankA2" value="A" v-model="form.rank2">
                <label for="rbRankB2" class="lbRank" :class="{'active': form.rank2 === 'B'}">B</label>
                <input type="radio" id="rbRankB2" value="B" v-model="form.rank2">
                <label for="rbRankC2" class="lbRank" :class="{'active': form.rank2 === 'C'}">C</label>
                <input type="radio" id="rbRankC2" value="C" v-model="form.rank2">
              </div>
              <div class="cbGroup">
                <label for="cbBox2" class="lbBox" :class="{'active': form.box2}"></label>
                <input type="checkbox" id="cbBox2" v-model="form.box2">
                <label for="cbManual2" class="lbManual" :class="{'active': form.manual2}"></label>
                <input type="checkbox" id="cbManual2" v-model="form.manual2">
              </div>
            </div>
          </div><!--/.collection-->
          <!-- コレクション3 -->
          <div class="collection" :class="{'show': form.num >= 3}">
            <label class="lbCollection">ソフト3</label>
            <div class="collectionItem">
              <div class="rbGroup">
                <label for="rbRankA3" class="lbRank" :class="{'active': form.rank3 === 'A'}">A</label>
                <input type="radio" id="rbRankA3" value="A" v-model="form.rank3">
                <label for="rbRankB3" class="lbRank" :class="{'active': form.rank3 === 'B'}">B</label>
                <input type="radio" id="rbRankB3" value="B" v-model="form.rank3">
                <label for="rbRankC3" class="lbRank" :class="{'active': form.rank3 === 'C'}">C</label>
                <input type="radio" id="rbRankC3" value="C" v-model="form.rank3">
              </div>
              <div class="cbGroup">
                <label for="cbBox3" class="lbBox" :class="{'active': form.box3}"></label>
                <input type="checkbox" id="cbBox3" v-model="form.box3">
                <label for="cbManual3" class="lbManual" :class="{'active': form.manual3}"></label>
                <input type="checkbox" id="cbManual3" v-model="form.manual3">
              </div>
            </div>
          </div><!--/.collection-->
          <!-- メモ -->
          <div class="memo">
            <label for="taMemo">メモ</label>
            <p><span>{{this.memoMaxCount-(form.memo.length)}}</span></p>
            <textarea id="taMemo" v-model="form.memo" cols="30" rows="7" maxLength="140"></textarea>
          </div>
        </div>
      </form>
      <div class="btnGroup">
        <div class="btnCancel button" @click="onBtnBack">キャンセル</div>
        <div class="btnUpdate button" @click="onBtnUpdate">更新する</div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import MyLoading from './Loading.vue';
import {mapGetters, mapActions} from 'vuex';
import { GAME_CONSOLES } from '../assets/data/game_consoles';
import { UPDATE_CURRENT, UPDATE_COLLECTION } from '../store/mutation-types';

export default {
  name: 'SoftForm',
  // ローカルコンポーネントを登録
  components: {
    MyLoading
  },
  data() {
    return {
      // ゲーム機の名前情報
      gameConsoles: [],
      // 選択中のソフト情報
      soft: {}, 
      // フォームの内容
      form: {
        id: 0, //ソフトID
        num: 0, // 所持数
        bookmark: false, // ふせん
        rank1: "", // 状態（ランク）1        
        box1: false, // 箱・ケース1
        manual1: false, // 説明書1      
        rank2: "", // 状態（ランク）2        
        box2: false, // 箱・ケース2
        manual2: false, // 説明書2      
        rank3: "", // 状態（ランク）3        
        box3: false, // 箱・ケース3
        manual3: false, // 説明書3      
        memo: '' // メモ
      },
      memoMaxCount: 140, //メモの最大入力文字数
      // 検証ルール
      rules: {
        num: [
          { required: true, message: '所持数が未入力です。', trigger: 'change' }
        ]
      },
      isLoading: false // ローディングの表示
    }
  },
  computed: {   
  ...mapGetters(['current', 'getCollectionById', 'gameConsole'])
  },
  // ページロード時の処理
  created() {
    // 選択中のソフトがない場合、リストページに戻る
    if (!this.current) {
      this.$router.push('/list');
    }
    // ゲーム機名の情報を取得
    this.gameConsoles = GAME_CONSOLES;
    // 選択中のソフト情報（オブジェクト）をsoftプロパティにセット
    this.soft = Object.assign({}, this.current);
  },
  // マウント（コンポーネント初期化）時の処理
  mounted() {
    // 選択中のソフトIDでstore内のコレクション情報を取得
    let s = this.getCollectionById(this.soft.id);
    // 既にコレクション情報が存在する場合は、その内容をフォームに反映
    if (s) {
      this.form.id = s.id; //ソフトID
      this.form.num = s.num; //所持数
      this.form.bookmark = s.bookmark; //ふせん
      this.form.rank1 = s.rank1; //状態（ランク）1
      this.form.box1 = s.box1; //箱・ケース1
      this.form.manual1 = s.manual1; //説明書1
      this.form.rank2 = s.rank2; //状態（ランク）2
      this.form.box2 = s.box2; //箱・ケース2
      this.form.manual2 = s.manual2; //説明書2
      this.form.rank3 = s.rank3; //状態（ランク）3
      this.form.box3 = s.box3; //箱・ケース3
      this.form.manual3 = s.manual3; //説明書3
      this.form.memo = s.memo; //メモ
    } else {
      // 選択中のソフトIDをform.idへセット
      this.form.id = this.soft.id;      
    }
  },
  methods: {
    // store情報のアクションを同名のメソッドに紐づけ
    ...mapActions([UPDATE_COLLECTION, UPDATE_CURRENT]),
    // [所持数]の+-入力ボタンクリック処理
    setNum(bool) {
      // [+]ボタンが押され数値が99以下の場合
      if (bool && this.form.num <= 99) {
        this.form.num++; // +1
      // [-]ボタンが押され数値が1以上の場合
      } else if (!bool && this.form.num >= 1) {
        this.form.num--; // -1
      }
      // 数値が0～2の場合、コレクション情報入力行のデータをクリア
      if (this.form.num == 0) {
        this.form.rank1 = "";
        this.form.box1 = "";
        this.form.manual1 = "";
      }
      if (this.form.num == 1) {
        this.form.rank2 = "";
        this.form.box2 = "";
        this.form.manual2 = "";
      }
      if (this.form.num == 2) {
        this.form.rank3 = "";
        this.form.box3 = "";
        this.form.manual3 = "";
      }
    },
    // コレクション入力フォームの内容をクリア
    clearForm() {
      this.form.id = 0; //ソフトID
      this.form.num = 0; //所持数
      this.form.bookmark = false; //ふせん
      this.form.rank1 = ""; //状態（ランク）1
      this.form.box1 = false; //箱・ケース1
      this.form.manual1 = false; //説明書1
      this.form.rank2 = ""; //状態（ランク）2
      this.form.box2 = false; //箱・ケース2
      this.form.manual2 = false; //説明書2
      this.form.rank3 = ""; //状態（ランク）3
      this.form.box3 = false; //箱・ケース3
      this.form.manual3 = false; //説明書3
      this.form.memo = ""; //メモ
    },
    // [<]（戻る）ボタンのクリックイベント
    onBtnBack() {
      // 選択中のソフト情報をクリア
      this[UPDATE_CURRENT](null);
      // フォーム内容をクリア
      this.clearForm();
      this.$nextTick(() => {
      // ソフト一覧ページへ
        this.$router.push({name: 'list', params: {outerSlide: 'slideL'}});
      });
    },
    // 「更新する」ボタンのクリックイベント
    onBtnUpdate() {
      // ローディングを表示
      this.isLoading = true;
      // 1秒後に次の処理を実行（ローディングを表示させるため）
      const loading = new Promise(function(resolve) {
        setTimeout(function() {
          resolve();
        }, 500);
      });
      // 選択中のソフト情報をstoreへ保存
      loading.then(() => {
        this[UPDATE_COLLECTION](Object.assign({}, this.form));
        // 選択中のソフト情報をクリア
        this[UPDATE_CURRENT](null);
        // フォーム内容をクリア
        this.clearForm();
        this.$nextTick(() => {
        // ソフト一覧ページへ
          this.$router.push({name: 'list', params: {outerSlide: 'slideL'}});
        });
      });
    }
  }
}
</script>

<style>
.softForm {
  z-index: 3;
}
.softForm .container {
  width: 320px;
	padding: 65px 10px 0;
}
/* タイトル検索ボックス */
.softForm h1 {
  position: relative;
  /*width: 100%;*/
  padding: 0 0 0 37px;
  font-size: 1.8rem;
  line-height: 1.8;
}
.softForm h1:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background: var(--consoleImage);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  z-index: 10;
}
/* ソフト情報 */
.softInfo {
  border-bottom: 1px solid #ddd;
  margin: 0 0 10px;
}
.softDetail h2 {
  position: relative;
  background: var(--softImage);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32px 32px;
  padding: 0 0 0 42px;
  font-size: 2.0rem;
}
.softDetail dl {
  display: flex;
	flex-flow: row wrap;
}
.softDetail dl .col2 {
  display: flex;
  width: 50%;
}
.softDetail dl div {
  display: flex;
  width: 100%;
}
.softDetail dl div dt {
  min-width: 70px;
}
.softDetail dl div dd {
  margin: 0;
}
/* コレクション情報入力フォーム */
.collectionForm {
  position: relative;
}
.collectionForm label {
  width: 70px;
  line-height: 2.7;
}
/* ソフト所持数&ブックマーク */
.collectionNumMark {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px;
}
/* ソフト所持数 */
.collectionForm .softNum {
  display: flex;
  position: relative;
  margin: 0 55px 0 0;
}
.collectionForm .softNum #ibNum {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  visibility: hidden;
}
.collectionForm .softNum .btnNum {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 2.0rem;
  line-height: 1.6;
  text-align: center;
}
.collectionForm .softNum .dispNum {
  margin: 0 5px 0 0;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2;
}
.collectionForm .softNum .btnMinus {
  margin: 0 5px 0 0;
  cursor: pointer;
}
.collectionForm .softNum .btnPlus {
  margin: 0;
  cursor: pointer;
}
/* ブックマーク */
.collectionForm .bookmark input {
  display: none;
}
.collectionForm .bookmark label {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url("../assets/images/ic_bookmark.png") no-repeat center center;
  background-size: 16px 16px;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.9;
  font-weight: 400;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}
.collectionForm .bookmark .active {
  background: url("../assets/images/ic_bookmark_s.png") no-repeat center center;
  background-size: 16px 16px;
  background-color: var(--fmBtnColor);
  color: #fff;
}
/* コレクション情報（1,2,3） */
.collection {
  display: flex;
  visibility: hidden;
  height: 0;
  padding: 0;
  border: none;
}
/* コレクション情報の入力不可 */
.collection.show {
  visibility: visible;
  height: auto;
  padding: 10px 0 10px;
  border-bottom: 1px solid #ddd;
}
.collection .lbCollection {
  width: 70px;
  vertical-align: middle;
}
.collectionItem {
  display: flex;
}
.collectionItem label {
  width: 60px;
}
/* ランク（ラジオボタン） */
.collectionItem .rbGroup {
  display: flex;
  position: relative;
  margin: 0 5px 0 0;
}
.collectionItem .rbGroup:after {
  display: block;
  position: absolute;
  top: 15px;
  left: 0;
  content: '';
  width: 130px;
  height: 10px;
  background-color: #ddd;
  z-index: 1;
}
.collectionItem .rbGroup input {
  display: none;
}
.collectionItem .rbGroup label {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px 0 0;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.8;
  font-weight: 400;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
  z-index: 2;
}
.collectionItem .rbGroup .active {
  background-color: var(--fmBtnColor);
  color: #fff;
}
/* 付属品（チェックボタン）  */
.cbGroup {
  display: flex;
}
.cbGroup input {
  display: none;
}
.cbGroup label {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px 0 0;
  text-align: center;
  font-size: 1.2rem;
  line-height: 3.0;
  font-weight: 400;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  cursor: pointer;
}
.collectionItem .active {
  background-color: var(--fmBtnColor);
  color: #fff;
}
.collectionItem .lbBox {
  background-image: url("../assets/images/ic_boxcase.png");
}
.collectionItem .lbBox.active {
  background-image: url("../assets/images/ic_boxcase_s.png");
}
.collectionItem .lbManual {
  background-image: url("../assets/images/ic_manual.png");
}
.collectionItem .lbManual.active {
  background-image: url("../assets/images/ic_manual_s.png");
}
/* メモ */
.collectionForm .memo {
  position: relative;
  max-width: 300px;
  padding: 10px 0 0;
  margin: 0 0 10px;
}
.collectionForm .memo label {
  width: auto;
  background: url("../assets/images/ic_memo.png") no-repeat left 35px center;
  background-size: 16px 16px;
}
.collectionForm .memo p {
  position: absolute;
  top: 15px;
  right: 10px;
}
.collectionForm .memo textarea {
  height: 130px;
  padding: 10px;
  border-radius: 5px;
}
.collectionForm .memo textarea:focus {
  outline: none;
}
/* ボタングループ */
.btnGroup {
  display: flex;
  width: 250px;
}
/* [キャンセル][更新する]ボタン */
.btnUpdate {
  background-color: var(--btnColor);
  color: #fff;
}
/* 右スライド（右から入り、右へ出る） */
.slideR-enter-active, .slideR-leave-active, .slideR-move {
  transition: transform 0.25s;
}
.slideR-enter, .slideR-leave-to {
  transform: translateX(100vw);
}
.slideR-enter-to, .slideR-leave {
  transform: translateX(0px);
}
</style>