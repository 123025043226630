<template>
  <div class="softList" :class="this.gameConsoles[gameConsole]['ini']">
    <header>
      <div class="header-inner">
        <!-- [<]戻るボタン -->
        <span class="btnBack" @click="onBtnBack"></span>
        <!-- タイトル検索ボックス --> 
        <div class="searchTitle">
          <input type="text" maxlength="30" placeholder="タイトル検索" v-model="filterForm.title" @change="onSearchTitle(filterForm.title)">
          <span class="searchClearBtn" @click="onSearchClear"></span>
        </div>
        <!-- イニシャル選択モーダル --> 
        <div class="initialTitle">
          <span class="modalBtn" @click="openModal">{{filterForm.initial}}</span>
          <input id="ibInitial" type="text" maxlength="1" v-model="filterForm.initial" @change="onInitialTitle(filterForm.initial)" disabled>
        </div>
      </div>
    </header>

    <!-- イニシャル選択モーダルウィンドウのコンポーネント -->
    <MyModal :isModalActive="isModal" v-on:closeModalWindow="closeModal"></MyModal>

    <transition :name="slideName" appear>
    <!-- データ絞り込みフォーム -->
    <div class="filter">
      <form class="filterBtn">
        <div class="filterBtnInner">
          <div class="rbGroup">
            <label for="rbAll" class="lbAll" :class="{'active': filterForm.dispTarget === 'all'}" @click="onRbClick('all')"></label>
            <input type="radio" id="rbAll" value="all" v-model="filterForm.dispTarget">
            <label for="rbHave" class="lbHave" :class="{'active': filterForm.dispTarget === 'have'}" @click="onRbClick('have')"></label>
            <input type="radio" id="rbHave" value="have" v-model="filterForm.dispTarget">
            <label for="rbDupli" class="lbDupli" :class="{'active': filterForm.dispTarget === 'dupli'}" @click="onRbClick('dupli')"></label>
            <input type="radio" id="rbDupli" value="dupli" v-model="filterForm.dispTarget">
            <label for="rbNone" class="lbNone" :class="{'active': filterForm.dispTarget === 'none'}" @click="onRbClick('none')"></label>
            <input type="radio" id="rbNone" value="none" v-model="filterForm.dispTarget">
            <label for="rbBookmark" class="lbBookmark" :class="{'active': filterForm.dispTarget === 'bookmark'}" @click="onRbClick('bookmark')"></label>
            <input type="radio" id="rbBookmark" value="bookmark" v-model="filterForm.dispTarget">
          </div>
        </div>
      </form>
      <div class="softsCount">
        <div class="softsCountInner">
          <p class="filterdSoftsCount"><span>{{filteredList.length}}</span>件</p>
          <p class="completeParcent">CL
            <span class="graph">
              <span class="bar" :style="{width: parcentBar}"></span>
              <span class="parcent">{{this.filteredListPacent}}&nbsp;%</span>
            </span>
          </p>
          <p class="allSoftsCount">所有数<span>{{this.filteredListSumNum}}</span></p>
        </div>
      </div>
    </div>
    </transition>

    <transition :name="slideName" appear>
    <div class="container">
      <!-- ソフト&コレクション情報リスト -->
      <div class="softData">
        <ul>
          <li class="dmyLi"></li>
          <!--<li v-for="(s, index) in filteredList" :key="s.id" @click="onclick(s)" :class="addFilteredClass(index)">{{s.name}}-->
          <li v-for="(s, index) in filteredList" :key="s.id" @click="onclick(s)" :class="[{'showRow': (Math.floor(index / OFFSET_ROW) <= groupNo)}, softIcClass(s.icNo)]">{{s.name}}
            <!-- ソフト1～3（状態、箱・ケース、説明書）、メモ、ブックマーク、のアイコン表示 -->
            <div class="icCollection">
              <div class="icCollection1" :class="{'show': s.num >= 1}">
                <span :class="{rank: s.rank1}">{{s.rank1}}</span>
                <span :class="{box: s.box1}"></span>
                <span :class="{manual: s.manual1}"></span>
              </div>
              <div class="icCollection2" :class="{'show': s.num >= 2}">
                <span :class="{rank: s.rank2}">{{s.rank2}}</span>
                <span :class="{box: s.box2}"></span>
                <span :class="{manual: s.manual2}"></span>
              </div>
              <div class="icCollection3" :class="{'show': s.num >= 3}">
                <span :class="{rank: s.rank3}">{{s.rank3}}</span>
                <span :class="{box: s.box3}"></span>
                <span :class="{manual: s.manual3}"></span>
              </div>            
              <span :class="{memo: s.memo}"></span>
              <span :class="{bookmark: s.bookmark}"></span>
            </div>
            <span class="softNum">{{s.num}}</span>
          </li>
          <div class="btnNextList button" @click="dispNextList" :class="{'showBtn': (groupNo < filteredListGroupNo)}">もっと見る</div>
        </ul>
      </div>
      <div class="btnMoveTop" v-if="posY > 100" @click="movePageTop"></div> 
    </div>
    </transition>
  </div>
</template>

<script>
import MyModal from './InitialModal.vue';
import { mapGetters, mapActions } from 'vuex';
import { GAME_CONSOLES } from '../assets/data/game_consoles';
import { UPDATE_CURRENT, UPDATE_DISPTARGET, UPDATE_KEYWORD, UPDATE_INITIAL, UPDATE_GAMECONSOLE, UPDATE_SOFT, UPDATE_SCROLLY, UPDATE_SHOW_GROUPNO } from '../store/mutation-types';

export default {
  name: 'SoftList',
  // ローカルコンポーネントを登録
  components: {
    MyModal
  },
  props: {
    outerSlide: String, //前後ページからのスライド方向
    dispDefault: Boolean //表示指定ボタンのデフォルト判定（1:全て）
  },
  data() {
    return {
      gameConsoles: [], // ゲーム機情報配列リスト
      softs: [], //ソフト情報リスト
      //コレクション情報（初期値）
      collection: {
        id: 0, //ソフトID
        num: 0, //所持数
        bookmark: false, //ふせん
        rank1: "", //状態（ランク）1
        box1: false, //箱・ケース1
        manual1: false, //説明書1
        rank2: "", //状態（ランク）2
        box2: false, //箱・ケース2
        manual2: false, //説明書2
        rank3: "", //状態（ランク）3
        box3: false, //箱・ケース3
        manual3: false, //説明書3
        memo: "" //メモ
      },
      slideName: String, //スライド名
      // 絞り込みフォームの内容
      filterForm: {
        title: "", // ソフトタイトル
        initial: "", //タイトルイニシャル（五十音）
        dispTarget: "all" //表示指定（初期値1:全て）
      },
      isModal: false, // イニシャル選択モーダルの表示
      posY: 0, // スクロール位置
      OFFSET_ROW: 100, // 表示するリスト行の単位（件）
      groupNo: 0 // 表示するリスト行のグループNo（～まで表示）
    }
  },
  created() {
    // ゲーム機情報の配列を取得
    this.gameConsoles = GAME_CONSOLES;
    //store内のソフト情報リストをsofts配列へコピー
    //オブジェクト配列の値（ディープ）コピーで、map関数で配列を、Object.assign関数で中のオブジェクトを複製（※参照コピーだとstore内のデータを更新してしまうのでエラーとなるため）
    this.softs = this.allSofts.map((obj) => Object.assign({}, obj));
    //store内の検索キーワードをソフトタイトル検索ボックスへセット
    if (this.keyword !== "") {
      this.filterForm.title = this.keyword;
    }
    //store内のイニシャルをイニシャル選択ボックスへセット
    if (this.initial !== "") {
      this.filterForm.initial = this.initial;
    }
    //ホームから表示した場合
    if (!this.dispDefault) {
      //store内の現在フィルターボタン情報を取得し、選択中の表示指定ボタン情報へセット
      this.filterForm.dispTarget = this.dispTarget;
    }
    //ソフト情報にコレクション情報を付与
    for (let i=0; i<this.softs.length; i++) {
      //ソフトIDでstore内のコレクション情報を取得
      let s = this.getCollectionById(this.softs[i].id);
      // 既存のコレクション情報が存在し、ソフトIDが一致する場合
      if (s && this.softs[i].id === s.id) {
        //既存コレクションの情報を追加
        Object.assign(this.softs[i], s);
      } else {
        this.collection['id'] = this.softs[i].id; //ソフトIDをセット
        //新規コレクションの情報（初期値）を追加
        Object.assign(this.softs[i], this.collection);
      }
    }
    // store内の表示するリストのグループNoを取得し、表示復帰
    this.groupNo = this.showGroupNo;

    this.slideName = this.outerSlide; //前後ページからのスライド方向をセット
  },
  mounted() {
    // コンポーネントの初期化・マウント処理完了後にスクロール処理
    this.$nextTick(() => {
      // スクロールイベントを登録
      window.addEventListener("scroll", this.handleScroll);
      // スクロール位置情報をもとにスクロール移動（コレクション入力ページから戻った時の位置復帰）
      window.scrollTo(0, this.scrollY);
    });
  },
  computed: {
    // store内のステートを同名の算出プロパティに紐づけ
    //...mapState(['rbCurrent']),
    // store内のゲッターを同名の算出プロパティに紐づけ
    ...mapGetters(['allSofts', 'softsCount', 'getCollectionById', 'dispTarget', 'keyword', 'initial', 'gameConsole', 'scrollY', 'showGroupNo']),

    // パーセントグラフのバー幅を返す
    parcentBar: function() {
      return this.filteredListPacent + '%';
    },
    // 所有ソフト件数率（XX.X）を返す
    filteredListPacent: function() {
      let count = 0;
      //this.filteredList.forEach(element => {
      this.softs.forEach(element => {
        if (element.num > 0) {count++;}
      });
      return ((count / this.softsCount) * 100).toFixed(1);      
    },
    // 総所有数の合計を返す
    filteredListSumNum: function() {
      let sum = 0;
      //this.filteredList.forEach(element => {
      this.softs.forEach(element => {
        sum += element.num;
      });
      return sum;
    },
    // ソフトのアイコン付加のクラス名を返す
    softIcClass: function() {
      return function(iconNo) {
        return this.gameConsoles[this.gameConsole]["ini"] + "_soft" + iconNo;
      }
    },
    // 絞り込み後のソフトリストの分割数を返す
    filteredListGroupNo: function() {
      // 表示リストの分割グループ数を取得
      let maxGroupNo = Math.floor(this.filteredList.length / this.OFFSET_ROW);
      return maxGroupNo;
    },
    // 絞り込み後のソフトリストを返す
    filteredList: function() {
      let newList = []; // リスト配列
      const keyword = this.filterForm.title; // 検索キーワードを取得
      const pattern = new RegExp(keyword); // 正規表現RegExpオブジェクトを生成
      let softTitle = "";
      let initial = this.filterForm.initial; // イニシャル選択値を取得

      // 表示するソフトリストを絞り込む
      for (let i=0; i<this.softsCount; i++) {
        let isShow = true;
        // タイトル検索キーワードが入力されている場合
        if (keyword !== '') {
          softTitle = this.softs[i].name;
          // i番目のソフトタイトルに検索パターンが含まれない場合
          if (!pattern.test(softTitle)) {
            isShow = false;
          }
        }
        // イニシャルが選択されている場合
        if (initial !== '') {
          // i番目のソフトのイニシャルに選択値が一致しない場合
          if (this.softs[i].ini !== initial) {
            isShow = false;
          }
        }
        // 絞り込みボタン（検索キーワード・イニシャル選択と併用）
        // [全て]ボタンが選択されている場合
        if (this.filterForm.dispTarget === "all") {
          //newList = this.softs; // 全てのソフト&コレクション情報を追加
        // [所持]ボタンが選択されている場合
        } else if (this.filterForm.dispTarget === "have") {
          // i番目のソフトの所持数が0の場合
          if (this.softs[i].num == 0) {
            isShow = false;
          }
        // [重複]ボタンが選択されている場合
        } else if (this.filterForm.dispTarget === "dupli") {
          // i番目のソフトの所持数が1以下の場合
          if (this.softs[i].num <= 1) {
            isShow = false;
          }
        // [未所持]ボタンが選択されている場合
        } else if (this.filterForm.dispTarget === "none") {
          // i番目のソフトの所持数が1以上の場合
          if (this.softs[i].num >= 1) {
            isShow = false;
          }
        // [ブックマーク]がチェックされている場合
        } else if (this.filterForm.dispTarget === "bookmark") {
          // i番目のソフトにふせんがチェックなしの場合
          if (!this.softs[i].bookmark) {
            isShow = false;
          }
        }
        if (isShow) {
          // 表示用リスト配列に追加
          newList.push(this.softs[i]);
        }
      }
      //console.log(newList);
      return newList;
    }
  },
  methods: {
    // store情報のアクションを同名のメソッドに紐づけ
    ...mapActions([UPDATE_CURRENT, UPDATE_DISPTARGET, UPDATE_KEYWORD, UPDATE_INITIAL, UPDATE_GAMECONSOLE, UPDATE_SOFT, UPDATE_SCROLLY, UPDATE_SHOW_GROUPNO]),
    // クリック時に現在のソフト情報を保存&フォームに移動
    onclick(soft) {
      this[UPDATE_CURRENT](soft); //選択したソフト情報をステートに保存
      this[UPDATE_SCROLLY](this.posY); //現在のスクロール位置をステートに保存
      this[UPDATE_SHOW_GROUPNO](this.groupNo); // 現在の表示するリストのグループNoをステートに保存
      this.slideName = "slideL"; // スライド情報をセット
      this.$router.push('/form'); // ソフト情報ページへ
    },
    // フィルターボタンのクリックイベント
    onRbClick(btn) {
      //選択したゲーム機情報をステートに保存
      this[UPDATE_DISPTARGET](btn);
      // 表示するリストのグループNoをリセット
      this.groupNo = 0;
      // ページトップへスクロール移動
      window.scrollTo(0, 0);
    },
    // 検索キーワード入力時のイベント
    onSearchTitle(keyword) {
      // 検索キーワードをステートに保存
      this[UPDATE_KEYWORD](keyword);
      // 表示するリストのグループNoをリセット
      this.groupNo = 0;
      // ページトップへスクロール移動
      window.scrollTo(0, 0);
    },
    // 検索キーワードのクリアボタン[×]のクリックイベント
    onSearchClear() {
      this.filterForm.title = "";
      this[UPDATE_KEYWORD]("");
      // ページトップへスクロール移動
      window.scrollTo(0, 0);
    },
    // イニシャル選択のイベント
    onInitialTitle(initial) {
      // 選択中のイニシャルをステートに保存
      this[UPDATE_INITIAL](initial);
    },
    // [<]（戻る）ボタンのクリックイベント
    onBtnBack() {
      // 検索ボックスのキーワードをクリア
      this[UPDATE_KEYWORD]("");
      // イニシャルの選択をクリア
      this[UPDATE_INITIAL]("");
      // 選択中のゲーム機のソフトリスト情報をクリア
      this[UPDATE_SOFT](null);
       //現在のスクロール位置をクリア
      this[UPDATE_SCROLLY](null);
       //現在の表示するリストのグループNoをクリア
      this[UPDATE_SHOW_GROUPNO](null);

      this.$nextTick(() => {
        // ホーム画面へ戻る
        this.$router.push({name: 'home', params: {/*outerSlide: 'slideR', dispDefault: true*/}});
      });
    },
    // イニシャル選択のモーダルを表示
    openModal() {
      this.isModal = true;
    },
    // イニシャル選択のモーダルを非表示
    closeModal() {
      this.isModal = false;
      // イニシャル選択が変更されていた場合
      if (this.filterForm.initial !== this.initial) {
        // ページトップへスクロール移動
        window.scrollTo(0, 0);
      }
      //store内のイニシャル情報をイニシャル選択ボックスへセット
      this.filterForm.initial = this.initial;
    },
    // スクロール位置の取得
    handleScroll() {
      this.posY = window.scrollY;
    },
    // [↑]ページトップボタンのクリックイベント
    //（iOSでスムーススクロールが効かない為の処理）
    movePageTop() {
      let startPosY = window.scrollY; //スクロール開始時のY位置
      let position = 0; // 次にスクロールするY位置
      let progress = 0; // 現在の進捗 0 ～ 100
      // スクロールスピードの加速
      var easeOut = function (p) {
          return p * (2 - p);
      };
      // スクロール処理
      let move = function () {
        progress++; // 進捗+1
        // スクロールする位置を計算
        position = startPosY - (startPosY * easeOut(progress / 20));
        // スクロール
        window.scrollTo(0, position);
        // 現在Y位置がページトップより下（>0）の場合、処理続行
        if (position > 0) {
            requestAnimationFrame(move);
        }
      };
      // 初回処理
      requestAnimationFrame(move);
    },
    // 「さらに読み込む」ボタンのクリックイベント
    dispNextList() {
      // 表示リストの分割グループ数を取得
      let maxGroupNo = this.filteredListGroupNo;
      if (this.groupNo < maxGroupNo) {
        this.groupNo++; // グループNo.0~2まで
      }
    }
  }
}
</script>

<style>
.softList {
  z-index: 2;
}
.softList .container {
	padding: 53px 0 0;
}
/* タイトル検索ボックス */
.searchTitle {
  position: relative;
  width: calc(100% - 92px);
  padding: 0 0 0 37px;
  margin: 0 10px 0 0;
}
.searchTitle::after {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background: var(--consoleImage);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  z-index: 10;
}
.searchTitle input {
  max-width: 100%;
  height: 33px;
  border-radius: 16px;
  font-size: 1.6rem;
  line-height: 1.4;
  padding: 0 15px 0 15px;
  margin: 0 0 8px;
}
.searchTitle input:focus {
  outline: none;
}
/* [×]ボタン */
.searchClearBtn {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  right: 6px;
  text-align: center;
  line-height: 0.9;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 1;
}
.searchClearBtn::before {
  content: '\0d7';
}
/* イニシャル選択ボックス */
.initialTitle {
  position: relative;
  width: 50px;
}
.initialTitle::before {
  display: block;
  position: absolute;
  top: 12px;
  right: 6px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  content: "";
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  z-index: 1;
}
.initialTitle input {
  position: relative;
  width: 50px;
  height: 35px;
  visibility: hidden;
}
.initialTitle .modalBtn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 0 0 10px;
  background-color: #fff;
  font-size: 1.5rem;
  line-height: 2.0;
  cursor: pointer;
}
/* 絞り込み */
.filter {
  width: 100vw;
  position: fixed;
  padding: 53px 0 0;
  z-index: 2;
}
/* 絞り込みチェックボタン */
.filterBtnInner {
  max-width: 1024px;
  margin: 0 auto;
}
.filterBtn .rbGroup {
  background-color: #f9f9f9;
  z-index: 10;
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 0 0 0px;
}
.filterBtn .rbGroup input {
  display: none;
}
.filterBtn .rbGroup label {
  display: inline-block;
  width: 24.95%;
  height: 40px;
  margin: 0 0.5% 0 0;
  text-align: center;
  line-height: 2.5;
  /*background-color: #eee;*/
  border: 1px solid #ddd;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  cursor: pointer;
}
.filterBtn .rbGroup .lbBookmark {
  margin: 0;
}
.filterBtn .rbGroup .active {
  background-color: var(--btnColor);
  color: #fff;
}
/* スーファミはボタン4色 */
.sfc .filterBtn .rbGroup .lbAll.active {background-color: var(--btnColor);}
.sfc .filterBtn .rbGroup .lbHave.active {background-color: var(--btnColor1);}
.sfc .filterBtn .rbGroup .lbDupli.active {background-color: var(--btnColor2);}
.sfc .filterBtn .rbGroup .lbNone.active {background-color: var(--btnColor3);}
.sfc .filterBtn .rbGroup .lbBookmark.active {background-color: var(--btnColor4);}

.filterBtn .rbGroup .lbAll {background-image: url("../assets/images/ic_all.png");}
.filterBtn .rbGroup .lbAll.active {background-image: url("../assets/images/ic_all_s.png");}
.filterBtn .rbGroup .lbHave {background-image: url("../assets/images/ic_have.png");}
.filterBtn .rbGroup .lbHave.active {background-image: url("../assets/images/ic_have_s.png");}
.filterBtn .rbGroup .lbDupli {background-image: url("../assets/images/ic_dupli.png");}
.filterBtn .rbGroup .lbDupli.active {background-image: url("../assets/images/ic_dupli_s.png");}
.filterBtn .rbGroup .lbNone {background-image: url("../assets/images/ic_none.png");}
.filterBtn .rbGroup .lbNone.active {background-image: url("../assets/images/ic_none_s.png");}
.filterBtn .rbGroup .lbBookmark {background-image: url("../assets/images/ic_bookmark.png");}
.filterBtn .rbGroup .lbBookmark.active {background-image: url("../assets/images/ic_bookmark_s.png");}

/* PCエンジン */
.pce .filterBtn {background-color: var(--subColor);}
.pce .filterBtn .rbGroup {background-color: var(--subColor);}
.pce .filterBtn .rbGroup label {background-color: #f9f9f9;}
.pce .filterBtn .rbGroup .active {background-color: var(--btnColor);}

/* メガドライブ */
.md .filterBtn {background-color: var(--subColor);}
.md .filterBtn .rbGroup {background-color: var(--subColor);}
.md .filterBtn .rbGroup label {background-color: var(--fmBtnColor);}
.md .filterBtn .rbGroup .active {background-color: var(--btnColor);}

/* ネオジオ */
.ng .filterBtn {background-color: var(--mainColor);}
.ng .filterBtn .rbGroup {background-color: var(--mainColor);}
.ng .filterBtn .rbGroup label {background-color: var(--subColor);}
.ng .filterBtn .rbGroup .active {background-color: var(--btnColor);}

/* GBアドバンス */
.gba .filterBtn {background-color: var(--subColor);}
.gba .filterBtn .rbGroup {background-color: var(--subColor);}
.gba .filterBtn .rbGroup label {background-color: #f9f9f9;}
.gba .filterBtn .rbGroup .active {background-color: var(--btnColor);}

/* 表示ソフト件数・全ソフト件数 */
.softsCount {
  display: fixed;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}
.softsCount .softsCountInner {
  display: flex;
  position: relative;
  max-width: 1024px;
  padding: 0 10px 0px;
  margin: 0 auto;
}
/* 表示件数 */
.filterdSoftsCount {
  width: 25%;
}
.filterdSoftsCount span {
  display: inline-block;
  width: 45px;
  margin: 0 5px 0 0;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}
/* コンプリート率 */
.completeParcent {
  display: flex;
  justify-content: center;
  position: relative;
  width: 35%;
  line-height: 1.8;
}
.graph {
  position :relative;
  width :100%;
  height: 20px;
  margin: 4px 5px 0;
  background-color: #eee;
  font-size: 1.4rem;
  font-weight: 400;
}
.graph .bar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #70f040;
}
.graph .parcent {
  height: 20px;
  position: absolute;
  top: 0;
  right: 5px;
  line-height: 1.4;
}
/* 総所有数 */
.allSoftsCount {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  font-size: 1.5rem;
  line-height: 1.9;
}
.allSoftsCount span {
  display: inline-block;
  min-width: 32px;
  /*width: 65px;*/
  height: 32px;
  margin: 0 2px 0 5px;
  background-color: #eee;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: center;
}
/* ソフト&コレクションのリスト */
.softData {
  padding: 105px 10px 0;
}
.softData ul {
  min-width: 100%;
}
.softData ul li {
  display: none;
  position: relative;
  min-height: 68px;
  padding: 10px 35px 10px 42px;
  border-bottom: 1px solid #ddd;
  background: var(--softImage);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32px 32px;
  background-position: 0px 9px;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
}
/* 表示する行のグループNo */
.softData ul li.showRow {
  display: block;
}
/* スライド時の行widthが短い場合に表示がずれる対策 */
.softData ul .dmyLi {
  opacity: 0;
  height: 0;
  min-height: 0;
  padding: 0;
}
.softData ul .dmyLi:before {
  content: '０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９０１２３４５６７８９';
}
/* ******上記の対策ここまで********************** */
/* 各ソフトのコレクション情報 */
/* ソフトの所持数 */
.softData ul li .softNum {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 10px;
  right: 0;
  background-color: #eee;
  border-radius: 16px;
  text-align: center;
  line-height: 1.9;
}
/* 箱・ケース、説明書、ブックマーク、メモのアイコン */
.icCollection {
  display: flex;
  padding: 5px 0 0;
}
.icCollection1, .icCollection2, .icCollection3 {
  display: flex;
  padding: 1px;
  margin: 0 5px 0 0;
}
.icCollection1.show, .icCollection2.show, .icCollection3.show {
  background-color: #eee;
}
.softData ul li span {
  display: block;
  width: 16px;
  height: 16px;
  margin: 0 2px 0 0;
}
/* 状態（ランク） */
.softData ul li .rank {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: #aaa;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  color: #fff;
}
/* 箱・ケース */
.softData ul li .box {
  background: url("../assets/images/ic_boxcase.png") no-repeat;
  background-size: 16px 16px;
}
/* 説明書 */
.softData ul li .manual {
  background: url("../assets/images/ic_manual.png") no-repeat;
  background-size: 16px 16px;
}
/* ブックマーク */
.softData ul li .bookmark {
  background: url("../assets/images/ic_bookmark.png") no-repeat;
  background-size: 16px 16px;
}
/* メモ */
.softData ul li .memo {
  background: url("../assets/images/ic_memo.png") no-repeat;
  background-size: 16px 16px;
}
/* [もっと見る]ボタン */
.btnNextList {
  display: none;
}
.showBtn {
  display: block;
}
/* [↑]ページトップへボタン */
.btnMoveTop {
  display: block;
  content: "↑";
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #c8c2be;
  position: fixed;
  bottom: 20px;
  right: 10px;
  opacity: 0.8;
}
.btnMoveTop::before {
  position: absolute;
  top: 15px;
  left: 13px;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* 左スライド（左から入り、左へ出る） */ 
.slideL-enter-active, .slideL-leave-active, .slideL-move {
  transition: transform 0.25s;
}
.slideL-enter, .slideL-leave-to {
  transform: translateX(-75vw);
}
.slideL-enter-to, .slideL-leave {
  transform: translateX(0px);
}
/* 右スライド（右から入り、右へ出る） */
.slideR-enter-active, .slideR-leave-active, .slideR-move {
  transition: transform 0.25s;
}
.slideR-enter {
  transform: translateX(100vw);
}
.slideR-leave-to {
  transform: translateX(100vw);
}
.slideR-enter-to, .slideR-leave {
  transform: translateX(0px);
}
</style>